<template>
  <div>
    <b-row>
      <b-col class="pr-25" md="3">
        <b-card style="height: 57rem" class="mb-0" no-body>
          <div class="border-bottom d-flex align-items-center px-1 py-1">
            <!-- <b-avatar
              v-if="user"
              variant="light-secondary"
              :text="user.name[0] + user.surname[0]"
              class="mr-1"
            ></b-avatar> -->
            <div class="ml-auto d-flex align-items-center">
              <b-button
                v-b-tooltip.hover.top="lang('t_webChat')"
                v-b-tooltip.hover.v-secondary
                pill
                class="ml-auto py-50 px-50"
                variant="flat-secondary"
              >
                <i class="bi bi-chat-dots font-medium-4"></i>
              </b-button>
              <b-button
                v-b-tooltip.hover.top="lang('t_whatsapp')"
                v-b-tooltip.hover.v-secondary
                pill
                class="ml-auto py-50 px-50"
                variant="flat-success"
              >
                <i class="bi bi-whatsapp font-medium-4 text-success"></i>
              </b-button>
              <b-button
                v-b-tooltip.hover.top="lang('t_instagram')"
                v-b-tooltip.hover.v-secondary
                pill
                class="ml-auto py-50 px-50"
                variant="flat-primary"
              >
                <i class="bi bi-instagram font-medium-4 text-primary"></i>
              </b-button>
              <b-button
                v-b-tooltip.hover.top="lang('t_messenger')"
                v-b-tooltip.hover.v-secondary
                pill
                class="ml-auto py-50 px-50"
                variant="flat-info"
              >
                <i class="bi bi-messenger font-medium-4 text-info"></i>
              </b-button>
            </div>
          </div>
          <div class="d-flex align-items-center px-1 py-1">
            <b-button
              v-b-tooltip.hover.top="lang('t_archivedMessages')"
              v-b-tooltip.hover.v-secondary
              @click="
                display_active_conversations = !display_active_conversations
              "
              pill
              class="mr-1 py-50 px-50"
              variant="flat-secondary"
            >
              <i
                v-if="display_active_conversations"
                class="bi bi-clock-history font-medium-4 text-secondary"
              ></i>
              <i
                v-else
                class="bi bi-arrow-left-circle font-medium-4 text-secondary"
              ></i>
            </b-button>

            <b-input-group
              v-if="display_active_conversations"
              class="input-group-merge slide-in"
            >
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>

              <b-form-input
                v-model="searchQuery"
                :placeholder="lang('t_search')"
              ></b-form-input>
            </b-input-group>
            <span
              v-else
              class="text-secondary font-weight-bolder font-medium-1 slide-in"
              >{{ lang("t_archivedMessages") }}</span
            >
          </div>
          <!-- ACTIVE -->

          <div
            v-if="display_active_conversations"
            :class="[
              isDark ? 'hover-effect-dark' : 'hover-effect',
              conversation &&
              conversation.conversation_id &&
              conversation.conversation_id === item.conversation_id
                ? 'bg-light-secondary'
                : '',
            ]"
            @click="get_conversation_details(item)"
            v-for="(item, i) in conversations"
            :key="i"
            class="cursor-pointer d-flex align-items-center px-1 py-50 slide-in"
          >
            <b-avatar
              :badge="false"
              :variant="false ? 'light-primary' : 'light-secondary'"
              class="mr-1"
            >
              <i
                :class="[
                  'bi',
                  item.details.custom_data.chat_type == 'whatsapp'
                    ? 'bi-whatsapp'
                    : 'bi-chat-text',
                  'font-medium-2',
                ]"
              ></i>
            </b-avatar>
            <div class="align-items-center w-100">
              <div class="d-flex align-items-center">
                <span :class="false ? 'font-weight-bolder' : ''">{{
                  item.details.name
                }}</span>
                <span
                  :class="false ? 'font-weight-bolder' : ''"
                  class="text-muted ml-auto font-small-2"
                  >{{
                    new Date(
                      item.details.history[0].timestamp
                    ).toLocaleTimeString(
                      "tr-TR",
                      (options = {
                        hour: "numeric",
                        minute: "2-digit",
                      })
                    )
                  }}</span
                >
              </div>
              <div class="d-flex align-items-center">
                <span
                  :class="false ? 'font-weight-bolder' : ''"
                  class="text-muted text-truncate"
                  style="width: 12vw"
                  >{{ item.details.history[0].text }}</span
                >
                <!-- <b-badge v-if="true" class="ml-auto" pill variant="primary"
                  >1</b-badge
                > -->
              </div>
            </div>
          </div>
          <!-- PASSIVE -->

          <div class="slide-in" v-else>
            <div
              :class="[
                isDark ? 'hover-effect-dark' : 'hover-effect',
                conversation &&
                conversation.conversation_id &&
                conversation.conversation_id === item.conversation_id
                  ? 'bg-light-secondary'
                  : '',
              ]"
              @click="get_conversation_details(item)"
              v-for="(item, i) in closed_conversations.filter(
                (e) => e.status == 'closed'
              )"
              :key="i"
              class="cursor-pointer d-flex align-items-center px-1 py-50"
            >
              <b-avatar
                :badge="false"
                :variant="false ? 'light-primary' : 'light-secondary'"
                class="mr-1"
              >
                <i
                  :class="[
                    'bi',
                    item.details.custom_data.chat_type == 'whatsapp'
                      ? 'bi-whatsapp'
                      : 'bi-chat-text',
                    'font-medium-2',
                  ]"
                ></i>
              </b-avatar>
              <div class="align-items-center w-100">
                <div class="d-flex align-items-center">
                  <span :class="false ? 'font-weight-bolder' : ''">{{
                    item.details.name
                  }}</span>
                  <span
                    :class="false ? 'font-weight-bolder' : ''"
                    class="text-muted ml-auto font-small-2"
                    >{{
                      new Date(
                        item.details.history[0].timestamp
                      ).toLocaleTimeString(
                        "tr-TR",
                        (options = {
                          hour: "numeric",
                          minute: "2-digit",
                        })
                      )
                    }}</span
                  >
                </div>
                <div class="d-flex align-items-center">
                  <span
                    :class="false ? 'font-weight-bolder' : ''"
                    class="text-muted text-truncate"
                    style="width: 12vw"
                    >{{ item.details.history[0].text }}</span
                  >
                  <!-- <b-badge v-if="true" class="ml-auto" pill variant="primary"
                  >1</b-badge
                > -->
                </div>
              </div>
            </div>
          </div>

          <!-- <div
            class="cursor-pointer border-top d-flex align-items-center px-1 py-50 hover-effect"
          >
            <b-avatar
              class="mr-1"
              variant="light-secondary"
              text="AK"
            ></b-avatar>
            <div class="align-items-center w-100">
              <div class="d-flex align-items-center">
                <span>Ali Karaduman</span>
                <span class="text-muted ml-auto font-small-2">14:39</span>
              </div>
              <div class="d-flex align-items-center">
                <span class="text-muted">Evet, tarafından</span>
                <b-badge v-if="false" class="ml-auto" pill variant="primary"
                  >1</b-badge
                >
              </div>
            </div>
          </div> -->
        </b-card>
      </b-col>
      <b-col class="pl-25" md="9">
        <b-card
          :style="{
            backgroundImage: 'url(' + chatBackgroundImage + ')',
          }"
          class="mb-0 chat-bg h-100"
          no-body
        >
          <!-- HEADER -->
          <b-card no-body class="mb-0 rounded-0">
            <div
              v-if="conversation"
              class="d-flex align-items-center px-1 py-1"
            >
              <b-avatar
                v-if="conversation && conversation.details.name"
                variant="light-secondary"
                :text="conversation.details.name[0]"
                class="mr-1"
              ></b-avatar>
              <span>{{ conversation.details.name }}</span>
              <div class="ml-auto d-flex align-items-center">
                <b-button
                  disabled
                  pill
                  class="ml-auto py-50 px-50 mr-50"
                  variant="flat-secondary"
                >
                  <i class="bi bi-camera-video font-medium-2"></i>
                </b-button>
                <b-button
                  pill
                  class="ml-auto py-50 px-50 mx-50"
                  variant="flat-secondary"
                >
                  <i class="bi bi-search font-medium-1"></i>
                </b-button>
                <b-dropdown
                  variant="flat-secondary"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                  toggle-class="px-50 py-50 rounded-circle"
                >
                  <template #button-content>
                    <i class="bi bi-three-dots-vertical font-medium-1"></i>
                  </template>

                  <b-dropdown-item @click="modal_finish_code = true">
                    <i class="bi bi-check-circle"></i>
                    <span class="align-middle ml-1">{{
                      lang("t_addFinishCode")
                    }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </b-card>

          <!-- BODY -->
          <div
            ref="msg_div"
            v-if="conversation"
            class="messages"
            style="height: 46rem !important; overfow-y: auto"
          >
            <!-- <center>
              <b-button
                size="sm"
                v-if="
                  conversation &&
                  conversation.details.custom_data.chat_type == 'whatsapp' &&
                  !conversation.chat_history
                "
                @click="getConversationHistory"
                class="my-1"
                variant="flat-secondary"
              >
                <i class="bi bi-clock-history mr-50"></i>

                Geçmiş konuşmaları getir</b-button
              >
            </center> -->

            <div v-for="(message, i) in conversation.history" :key="i">
              <div v-if="isNewDay(conversation.history, i)">
                <div class="date-line">
                  <small class="line"></small>
                  <b-badge class="mt-50" pill variant="light-secondary">
                    <span class="font-weight-lighter">
                      {{ formatDate(message.timestamp) }}
                    </span>
                  </b-badge>
                  <small class="line"></small>
                </div>
              </div>

              <b-row class="px-1">
                <b-col>
                  <span
                    v-if="message.type == 'text'"
                    :class="
                      message.sender == 'customer'
                        ? isDark
                          ? 'msg_from bg-secondary text-white'
                          : 'msg_from bg-white text-dark'
                        : 'msg_to bg-primary text-white'
                    "
                  >
                    {{ message.text }}
                    <small class="time text-muted">
                      {{
                        new Date(message.timestamp).toLocaleTimeString(
                          "tr-TR",
                          (options = {
                            hour: "numeric",
                            minute: "2-digit",
                          })
                        )
                      }}
                    </small>
                  </span>
                  <span class="msg_note bg-warning" v-else>
                    <i class="bi bi-bookmark-plus mr-50"></i>
                    {{ message.text }}
                    <!-- <small class="time">
                      {{
                        new Date(message.created_at).toLocaleTimeString(
                          "tr-TR",
                          (options = {
                            hour: "numeric",
                            minute: "2-digit",
                          })
                        )
                      }}
                    </small> -->
                  </span>
                </b-col>
              </b-row>
            </div>
          </div>
          <!-- FOOTER -->
          <div
            v-if="conversation"
            class="d-flex align-items-center fixed-bottom position-absolute px-1 py-1 bg-transparent border-top"
          >
            <b-button pill variant="flat-secondary" class="py-50 px-50">
              <i
                style="
                  -webkit-transform: rotate(30deg);
                  -moz-transform: rotate(30deg);
                  -ms-transform: rotate(30deg);
                  -o-transform: rotate(30deg);
                  transform: rotate(30deg);
                  display: inline-block;
                "
                class="bi bi-paperclip font-medium-2"
              ></i>
            </b-button>
            <b-button
              pill
              variant="flat-secondary"
              class="py-50 px-50 mr-1"
              id="note-popover-reactive-1"
              ref="button2"
              @click="notePopoverShow = true"
            >
              <i class="bi bi-bookmark-plus"></i>
            </b-button>
            <b-form-input
              @keyup.enter="send_message('reply')"
              ref="inputRef"
              v-model="message"
              :placeholder="lang('t_yourMessage')"
            />
            <!-- <b-button pill variant="flat-secondary" class="py-50 px-50 ml-1">
              <i class="bi bi-send font-medium-1"></i>
            </b-button> -->
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      :title="lang('t_finishCode')"
      static
      centered
      hide-header-close
      no-close-on-esc
      no-close-on-backdrop
      :ok-title="lang('t_save')"
      :cancel-title="lang('t_cancel')"
      @ok="save_finish_code"
      v-model="modal_finish_code"
      @show="resetFinishCode"
    >
      <!-- <template #modal-title>
        <code>{{ lang("t_remainingTime") }} : {{ acw_timer }}</code>
      </template> -->
      <b-form-group>
        <v-select
          v-model="interaction_finish_code"
          :options="finish_codes"
          :reduce="(val) => val"
          :placeholder="lang('t_finishCode')"
          label="finish_code"
        >
        </v-select>
      </b-form-group>

      <b-form-group>
        <b-form-textarea
          v-model="interaction_note"
          :placeholder="lang('t_note')"
          rows="5"
        >
        </b-form-textarea>
      </b-form-group>
    </b-modal>
    <b-popover
      boundary="viewport"
      class="border-0"
      ref="popover"
      target="popover-reactive-1"
      triggers="click"
      :show.sync="popoverShow"
      placement="auto"
      container="my-container"
      @show="onShow"
    >
      <template v-slot:title>
        <div
          style="width: 400px"
          class="d-flex justify-content-between align-items-center"
        >
          <span>Etiket</span>
          <b-button
            class="close"
            variant="transparent"
            aria-label="Close"
            @click="onClose"
          >
            <span class="d-inline-block text-white" aria-hidden="true"
              >&times;</span
            >
          </b-button>
        </div>
      </template>

      <div v-if="conversation" style="min-height: 10vh">
        <b-form-group class="mb-1">
          <v-select
            multiple
            :reduce="(val) => val"
            v-model="conversation.tags"
            :options="tags"
            label="name"
          >
          </v-select>
        </b-form-group>

        <b-button
          class="mt-50 float-right"
          size="sm"
          variant="primary"
          @click="saveTags"
        >
          Ok
        </b-button>
        <b-button
          size="sm"
          variant="danger"
          class="mr-1 mt-50 float-right"
          @click="onClose"
        >
          Cancel
        </b-button>
      </div>
    </b-popover>
    <b-popover
      boundary="viewport"
      class="border-0"
      ref="note_popover"
      target="note-popover-reactive-1"
      triggers="click"
      :show.sync="notePopoverShow"
      placement="auto"
      container="my-container"
      @show="onShow"
    >
      <template v-slot:title>
        <div
          style="width: 400px"
          class="d-flex justify-content-between align-items-center"
        >
          <span>Not Ekle</span>
          <b-button
            class="close"
            variant="transparent"
            aria-label="Close"
            @click="onClose"
          >
          </b-button>
        </div>
      </template>

      <div v-if="conversation">
        <b-form-group class="mb-1">
          <b-form-textarea
            v-model="note"
            rows="3"
            :placeholder="lang('t_note')"
          ></b-form-textarea>
        </b-form-group>

        <b-button
          class="mb-1 mt-50 float-right"
          size="sm"
          variant="flat-primary"
          @click="send_message('note')"
        >
          Ok
        </b-button>
        <b-button
          size="sm"
          variant="flat-danger"
          class="mb-1 mr-1 mt-50 float-right"
          @click="onClose"
        >
          Cancel
        </b-button>
      </div>
    </b-popover>
  </div>
</template>

<script>
import {
  BForm, BListGroup, BListGroupItem, BMediaAside, BMediaBody, BCardFooter,
  BFormGroup, BButtonGroup, VBTooltip,
  BFormInvalidFeedback,
  BFormCheckbox, BCardText,
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BInputGroup, BInputGroupPrepend,
  BBadge, BDropdown, BDropdownItem, BPagination, BModal, BFormTextarea, BPopover
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BInputGroup, BInputGroupPrepend,
    BPopover,
    BCardText,
    BMediaAside, BMediaBody, BCardFooter,
    BListGroup, BListGroupItem,
    BButtonGroup,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BFormTextarea
  },

  data() {
    return {
      lang: _l(window.localStorage.getItem('language') || "tr"),
      display_active_conversations: true,
      finish_codes: [],
      conversations: [],
      closed_conversations: [],
      show_passive_conversations: false,
      tags: [{ name: 'Kültür' }, { name: 'BilgiPaylaşımı' }, { name: 'Gezi' }],
      conversation: null,
      searchQuery: '',
      message: '',
      modal_finish_code: false,
      interaction_finish_code: '',
      interaction_note: '',
      popoverShow: false,
      notePopoverShow: false,
      note: '',
      user: {}

    }
  },
  setup() {
    // App Name
    const { skin } = useAppConfig()
    const isDark = computed(() => skin.value === 'dark')

    return {
      skin,
      isDark,
    }
  },
  computed: {
    chatBackgroundImage() {
      return this.isDark ? '/chat_bg_dark.jpg' : '/chat_bg.jpg';
    }
  },
  mounted: async function () {
    this.user = globalThis.user;
    this.$http_in.defaults.headers.common['text-channel-authorization'] = globalThis.user.text_channel_token;
    this.$http_in.defaults.headers.common['text-channel-user'] = globalThis.user.text_channel_user_id;
    this.finish_codes = globalThis.finish_codes.filter(e => e.is_chat_finish_code);
    globalThis.external_chat = this;
    await this.get_conversations();
  },
  methods: {
    async getConversationHistory() {
      const remote_number = this.conversation.customer.name.trim().slice(-10);
      const domain = globalThis.user.domain;
      var response = (await this.$http_in.get(`/text-channel/v1/ConversationHistory/${domain}/${remote_number}`)).data;
      this.conversation.chat_history = true;
      this.conversation.messages.unshift(...response);
      this.scrollToBottom();
      console.log(response);


    },

    getChatType(item) {
      if (item.metadata) {
        return item.metadata.type;
      }
      return item.source

    },
    resetFinishCode() {
      this.interaction_finish_code = '';
      this.interaction_note = '';
    },
    focusRef(ref) {
      this.$nextTick(() => {
        this.$nextTick(() => {
          (ref.$el || ref).focus()
        })
      })
    },
    onClose() {
      this.popoverShow = false;
      this.notePopoverShow = false;
    },
    async saveTags() {
      await this.$http_in.put(`/text-channel/v1/SetConversationTags/${this.conversation.id}`, {
        tags: this.conversation.tags
      });
      this.popoverShow = false;
    },

    onShow() {
      this.note = '';
    },
    save_finish_code(bvModalEvent) {
      if (this.interaction_finish_code == '') {

        this.$bvToast.toast(`Sonuç kodu boş bırakılamaz...`, {
          title: `Uyarı`,
          toaster: 'b-toaster-bottom-right',
          solid: true,
          appendToast: false,
          variant: 'warning',
        })
        bvModalEvent.preventDefault();
        return;
      }
      // clearInterval(globalThis.LayoutContentRendererDefault.acw_interval);

      console.log("1");
      globalThis._voyce.$qm.emit('qmgr:FinishInteraction', {
        queue: this.conversation.details.custom_data.queue,
        interactionId: this.conversation.conversation_id,
      });
      console.log("2");


      globalThis._voyce.$qm.emit('qmgr:FinalizeInteraction', {
        queue: this.conversation.details.custom_data.queue,
        interactionId: this.conversation.conversation_id,
        verdict: this.interaction_finish_code.finish_code,
        details: {
          note: this.interaction_note,
          finish_code: { ...this.interaction_finish_code }
        },
      });
      console.log("3");

      this.close_conversation();
    },

    isNewDay(messages, index) {
      if (index === 0) {
        return true; // İlk mesaj her zaman yeni bir gün olarak kabul edilir
      } else {
        const currentMessage = new Date(messages[index].timestamp);
        const previousMessage = new Date(messages[index - 1].timestamp);
        return (
          currentMessage.getDate() !== previousMessage.getDate() ||
          currentMessage.getMonth() !== previousMessage.getMonth() ||
          currentMessage.getFullYear() !== previousMessage.getFullYear()
        );
      }
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString("tr-TR", {
        // weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    formatDateString(date) {
      const d = new Date(date);
      const year = d.getFullYear();
      const month = String(d.getMonth() + 1).padStart(2, '0');
      const day = String(d.getDate()).padStart(2, '0');
      const hours = String(d.getHours()).padStart(2, '0');
      const minutes = String(d.getMinutes()).padStart(2, '0');
      const seconds = String(d.getSeconds()).padStart(2, '0');

      const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
      return formattedDate;
    },

    scrollToBottom: () => {
      var scr = globalThis.external_chat.$refs.msg_div;
      if (![null, undefined].includes(scr)) {
        scr.scrollTop = scr.scrollHeight;
      }
    },
    confirmText(item) {
      this.currentData = item;
      console.log("confirmText", item);
      this.$swal({
        title: this.lang('t_deleteRecord'),
        text: this.lang('t_areYouSureYouWantToDeleteTheSelectedRecord'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.lang('t_yes'),
        cancelButtonText: this.lang('t_cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',

        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.delete_record();
        }
      })
    },
    close_conversation: async function () {
      var response = (await this.$http_in.get(`/text-channel/v1/EndConversation/${this.conversation.conversation_id}`)).data;

      await this.get_conversations();
      this.conversation = null;
    },
    get_conversations: async function () {
      var response = (await this.$http_in.get(`/text-channel/v1/Conversation`)).data;
      // for (const item of response.data) {
      //   item.messages = item.messages.sort((a, b) => {
      //     const dateA = new Date(a.created_at);
      //     const dateB = new Date(b.created_at);
      //     return dateA - dateB;
      //   });
      // }
      this.conversations = response;
      // var today = new Date().toISOString().slice(0, 10);

      // this.closed_conversations = this.conversations.filter(function (conversation) {
      //   var closedAt = new Date(conversation.closed_at).toISOString().slice(0, 10);
      //   return closedAt === today;
      // });

      console.log("conversations", response);

    },
    get_conversation_details: async function (item) {
      console.log("item", item)
      this.conversation = item;

      setTimeout(() => {
        this.scrollToBottom();

      }, 100);
      this.$refs.inputRef.focus();

      // console.log("conversation", response);
    },
    send_message: async function (type) {
      var response = (await this.$http_in.post(`/text-channel/v1/SendMessage`, {
        message: this.message,
        conversation_id: this.conversation.conversation_id,
        type: 'text'
      })).data;
      if (this.conversation) {
        this.conversation.history.push({
          sender: "agent", text: this.message, timestamp: new Date().toISOString(), type: "text"
        });
      }
      this.message = '';
      this.get_conversation_details(this.conversation);
      this.$refs.inputRef.focus();
      this.notePopoverShow = false;
    },



  },

}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.date-line {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.line {
  flex-grow: 1;
  height: 0px;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 0 10px;
}

.date {
  padding: 0 10px;
  font-weight: bold;
  z-index: 1;
  background-color: #fff;
  position: relative;
}

.chat-bg {
  background-image: url("/chat_bg.jpg");
}
.hover-effect:hover {
  background-color: #f8f8f8;
}
.hover-effect-dark:hover {
  background-color: #161d31;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
